import { registerApplication, start } from "single-spa";

import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine
} from "single-spa-layout";

const routes = constructRoutes(document.querySelector("#single-spa-layout"));
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  }
});
const layoutEngine = constructLayoutEngine({ routes, applications });

registerApplication<{}>({
  name: "@einstein/payment-header",
  app: () => System.import("@einstein/payment-header"),
  activeWhen: ["/app-payment"]
});

registerApplication<{}>({
  name: "@einstein/payment",
  app: () => System.import("@einstein/payment"),
  activeWhen: ["/app-payment"]
});

// Landing page

registerApplication<{}>({
  name: "@einstein/top-bar",
  app: () => System.import("@einstein/top-bar"),
  activeWhen: [(location) => location.pathname === "/"]
});

registerApplication<{}>({
  name: "@einstein/hero",
  app: () => System.import("@einstein/hero"),
  activeWhen: [(location) => location.pathname === "/"]
});

registerApplication<{}>({
  name: "@einstein/how-to-use",
  app: () => System.import("@einstein/how-to-use"),
  activeWhen: [(location) => location.pathname === "/"]
});

registerApplication<{}>({
  name: "@einstein/when-to-use",
  app: () => System.import("@einstein/when-to-use"),
  activeWhen: [(location) => location.pathname === "/"]
});

registerApplication<{}>({
  name: "@einstein/quiz",
  app: () => System.import("@einstein/quiz"),
  activeWhen: [
    "/careplus/quiz",
    "/careplus/quiz/quizInitial",
    "/careplus/quiz/quizAnswer",
    "/careplus/quiz/quizUnreadable",
    "/careplus/quiz/quizReadable",
    "/careplus/quiz/quizScheduleAfter",
  ],
});

registerApplication<{}>({
  name: "@einstein/telemedicine",
  app: () => System.import("@einstein/telemedicine"),
  activeWhen: [
    "/careplus/telemedicine",
    "/careplus/telemedicine/telemedicineConsultation",
    "/careplus/telemedicine/telemedicineWaitingLine",
  ],
});

// registerApplication<{}>({
//   name: "@einstein/faq",
//   app: () => System.import("@einstein/faq"),
//   activeWhen: [(location) => location.pathname === "/"],
// });

registerApplication<{}>({
  name: "@einstein/pricing",
  app: () => System.import("@einstein/pricing"),
  activeWhen: [(location) => location.pathname === "/"]
});

registerApplication<{}>({
  name: "@einstein/footer",
  app: () => System.import("@einstein/footer"),
  activeWhen: [(location) => location.pathname === "/"]
});

registerApplication<{}>({
  name: "@einstein/cookies",
  app: () => System.import("@einstein/cookies"),
  activeWhen: ["/"]
});

registerApplication<{}>({
  name: "@einstein/home",
  app: () => System.import("@einstein/home"),
  activeWhen: [
    "/home",
    "/consultas-agendadas",
    "/historico",
    "/einstein-corporate",
    "/dependentes",
    "/fale-conosco",
    "/terms-provider",
    "/sintomas",
    "/triagem"
  ]
});

registerApplication<{}>({
  name: "@einstein/login",
  customProps: {
    main: {
      logo: "https://einstein-ui-conecta-lib.s3.amazonaws.com/Logos/EinsteinLogo.svg"
    },
    login: {
      backgroundImage: "/img/bg-home.png",
      defaultRedirect: "L3Byb3ZlZG9yZXM",
      clientId: "conecta-web"
    },
    register: {
      title: `Cadastre-se com seus dados para utilizar os serviços disponíveis no Einstein Conecta.`,
      serviceTerms: "https://telemedicina.einstein.br/termos.pdf",
      dataTerms: "https://telemedicina.einstein.br/termos.pdf"
    }
  },
  app: () => System.import("@einstein/login"),

  activeWhen: [
    (location) => location.pathname === "/login",
    "/register",
    "/recover-password",
    "/forget-password",
    "/reset-password",
    "/support",
    "/warning-screen"
  ]
});

registerApplication<{}>({
  name: "@einstein/conecta-b2c",
  app: () => System.import("@einstein/conecta-b2c"),
  activeWhen: ["/", "/privacidade", "/teleconsulta/termo"]
});

registerApplication<{}>({
  name: "@einstein/account-support",
  app: () => System.import("@einstein/account-support"),
  activeWhen: ["/account-support"]
});

registerApplication<{}>({
  name: "@einstein/telemedicina-b2b",
  app: () => System.import("@einstein/telemedicina-b2b"),
  activeWhen: ["/b2b"]
});


start({
  urlRerouteOnly: true
});
